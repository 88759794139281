import React from 'react';
import beer from '../../images/beer.jpg'
import willy from '../../images/willy.jpg'
import cvk from '../../images/cvk.jpg'

const Drinks = () => {
  return (
    <div className="drinks-container">
      <div className="header-img">
        <img src={beer} />
      </div>
      <div className="heading">Beers</div>
      <div className="drink-contents">
        {beers.map((dish, index) => {
          return (
            <div className="drink-item" key={index}>
              <p>{dish.name} {dish.price}</p>
              <p className="description">{dish.description.map((desc, index) => <span key={index}>{desc}</span>)}</p>
            </div>
          )
        })}
      </div>
      <div className="heading">Wine</div>
      <div className="drink-contents">
        {wines.map(dish => {
          return (
            <div className="drink-item">
              <p>{dish.name} {dish.price}</p>
              <p className="description">{dish.description.map(desc => <span>{desc}</span>)}</p>
            </div>
          )
        })}
      </div>
      <div className="header-img">
        <img src={willy} />
      </div>
      <div className="heading">Cocktails</div>
      <div className="drink-contents">
        {cocktails.map((dish, index) => {
          return (
            <div className="drink-item" key={index}>
              <p>{dish.name} {dish.price}</p>
              <p className="description">{dish.description.map((desc, index) => <span key={index}>{desc}</span>)}</p>
            </div>
          )
        })}
      </div>
      <div className="heading">Liquor</div>
      <div className="drink-contents">
        {liquor.map((dish, index) => {
          return (
            <div className="drink-item" key={index}>
              <p>{dish.name} {dish.price}</p>
              <p className="description">{dish.description.map((desc, index) => <span key={index}>{desc}</span>)}</p>
            </div>
          )
        })}
      </div>
      <div className="header-img">
        <img style={{objectPosition: "0% 15%"}} src={cvk} />
      </div>
      <div className="heading">Bomb Shots</div>
      <div className="drink-contents">
        {bombs.map((dish, index) => {
          return (
            <div className="drink-item" key={index}>
              <p>{dish.name} {dish.price}</p>
              <p className="description">{dish.description.map((desc, index) => <span key={index}>{desc}</span>)}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const beers = [
  {
    name: 'Domestic (341ml)',
    description: ['Canadian', 'Budweiser', 'Coors Light'],
    price: '4.49',
    img: ''
  },
  {
    name: 'Import (330ml)',
    description: ['Corona', 'Heineken', 'Stella Artois'],
    price: '5.49',
    img: ''
  },
  {
    name: 'Asahi (16oz)',
    description: [''],
    price: '7.99',
    img: ''
  },
  {
    name: 'Asahi (64oz)',
    description: [''],
    price: '20.99',
    img: ''
  },
  {
    name: 'Amsterdam Blonde (can)',
    description: [''],
    price: '6.50',
    img: ''
  },
  {
    name: 'Amsterdam Speed (can)',
    description: [''],
    price: '6.50',
    img: ''
  },
  {
    name: 'Amsterdam Blonde (16oz)',
    description: [''],
    price: '5.50',
    img: ''
  },
  {
    name: 'Amsterdam Blonde (32oz)',
    description: [''],
    price: '11.99',
    img: ''
  },
  {
    name: 'Amsterdam Blonde (64oz)',
    description: [''],
    price: '17.99',
    img: ''
  },
  {
    name: 'Red Horse',
    description: [''],
    price: '8.99',
    img: ''
  }
]

const wines = [
  {
    name: "Jacob's Creek Moscato (7oz)",
    description: [''],
    price: '7.00',
    img: ''
  },
  {
    name: "Jacob's Creek Moscato (750ml bottle)",
    description: [''],
    price: '24.99',
    img: ''
  },
  {
    name: "Jackson-Triggs Merlot (7oz)",
    description: [''],
    price: '7.00',
    img: ''
  },
  {
    name: "Jackson-Triggs Merlot (750ml bottle)",
    description: [''],
    price: '24.99',
    img: ''
  }
]

const liquor = [
  {
    name: 'Shots (1oz)',
    description: ['St Remy XO', 'Jack Daniels', 'Crown Royal', 'Captain Morgan', 'Jose Cuervo', 'Beefeater', 'Jägermeister', 'Kraken'],
    price: '5.00',
    img: ''
  },
  {
    name: 'Premium Shots (1oz)',
    description: ['Don Julio Resposado $13', 'Patron $11', 'Hennessy VS - $8', 'Grand Marnier $7', 'Johnny Walker Black Label - $7', 'Grey Goose - $7', 'Courvoisier VS Cognac - $7'],
    price: '',
    img: ''
  }
]

const bombs = [
  {
    name: 'Jägerbomb (1oz)',
    price: '6.99',
    description: [],
    img: ''
  },
  {
    name: 'Beergin (1oz)',
    price: '5.99',
    description: [],
    img: ''
  },
  {
    name: 'Kraken in the Deep (1oz)',
    price: '5.99',
    description: [],
    img: ''
  },
  {
    name: 'Captain vs Kraken (2oz)',
    price: '10.99',
    description: [],
    img: ''
  }
]

const cocktails = [
  {
    name: 'Beach Please',
    description: ['Peach Schnapps, vodka, orange juice, cranberry juice'],
    price: '6.99',
    img: ''
  },
  {
    name: 'Tropical Tea',
    description: ['Mango, crown royal, and lemon tea'],
    price: '6.99',
    img: ''
  },
  {
    name: 'Calamansi Whiskey Sour',
    description: ['Calamansi, crown royal, and lemon lime soda'],
    price: '6.99',
    img: ''
  },
  {
    name: 'Chilly Willy',
    description: ['Bacardi superior white rum mixed with blue curacao, pineapple juice, topped with lemon lime soda'],
    price: '6.99',
    img: ''
  },
  {
    name: 'Lychee Smash',
    description: ['Fresh mint and lychee muddled in a cup, mixed with gin & topped with lemon lime soda'],
    price: '6.99',
    img: ''
  },
  {
    name: 'Mint Mojito',
    description: ['Fresh mint stirred with sugar and mixed with barcardi white rum, lemon lime soda and water'],
    price: '6.99',
    img: ''
  },
  {
    name: 'Pinay Colada',
    description: ['White rum, pina colada mixture and coconut milk blended with ice'],
    price: '7.49',
    img: ''
  },
  {
    name: 'Strawberry Daiquiri',
    description: [],
    price: '7.49',
    img: ''
  },
  {
    name: 'Mango Daiquiri',
    description: [],
    price: '7.49',
    img: ''
  }
]

export default Drinks;
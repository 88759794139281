import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagram, faYelp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {
  const map_url = `https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ9ahKDmovK4gRoYsO8HROSjM&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`;
  return (
    <div className="contact">
      <strong>Phone</strong>
      <p>(905) 597-5252</p>
      <strong>Address</strong>
      <p>Limitless Bar & Grill</p>
      <p>1450 Centre Street, Unit 3</p>
      <p>L4J 3N1, Thornhill, ON</p>
      <iframe className="location" frameBorder="0" style={{border:"0"}} src={map_url} allowFullScreen></iframe>
    </div>
  )
}

export default Contact;
import React, { useState, useEffect } from 'react';
import Starters from './Starters.js'
import Pulutan from './Pulutan.js'
import Mains from './Mains.js'
import Burgers from './Burgers.js'
import Seafood from './Seafood.js'
import Fries from './Fries.js'
import Drinks from './Drinks.js'
import Specials from './Specials.js'
import Buckets from './Buckets.js'
import { getMenu } from './../../api/ProductAPI.js'

const Menu = () => {
  const [menu, setMenu] = useState(false)
  useEffect(() => {
    getMenu(setMenu)
  }, [])
  if(!menu) {
    return <div>loading...</div>
  }
  if(menu) {
    return (
      <div className="menu">
        <Specials contents={menu['special'] || []} />
        <Starters contents={menu['starter'] || []} />
        <Fries contents={menu['fries'] || []} />
        <Burgers contents={menu['burger'] || []} />
        <Seafood contents={menu['seafood'] || []} />
        <Mains contents={menu['main'] || []} />
        <Drinks contents={menu['drink'] || []} />
        <Buckets contents={menu['bucket'] || []} />
      </div>
    )
  } 
}

export default Menu;
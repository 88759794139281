import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie'
import { getCurrentUser } from '../../api/AuthAPI'
import { Redirect, Route } from 'react-router-dom';

const AuthRoute = props => {
  const [loading, setLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState(null)
  const token = Cookies.get('access_token')
  const setUser = async() => {
    const user = await getCurrentUser()
    if(user) {
      setCurrentUser(user)
    }
    setLoading(false)
  }
  if(token && !currentUser) {
    setUser()
  }
  if(token && loading) {
    return <div>Loading...</div>
  }
  return <VerifiedRoute verified={currentUser} {...props} />
}


const VerifiedRoute = ({ verified, path, refetch, ...props }) => {
  return verified ? (
    <Route refetchVerification={refetch} {...props} />
  ) : (
    <Redirect to={{ pathname: "/login", state: { referrer: path } }} />
  );
}

export default AuthRoute;

import React, { Component } from 'react';
import Select from 'react-select';
import emailjs from 'emailjs-com';
import Infinity from './Infinity'
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import './index.scss';

const options = [
  {label: "1", value: "1"},
  {label: "2", value: "2"},
  {label: "3", value: "3"},
  {label: "4", value: "4"},
  {label: "5", value: "5"},
  {label: "6", value: "6"},
  {label: "7", value: "7"},
  {label: "8+", value: "8+"}
]

const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

class Reserve extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      nameError: "",
      mobile: "",
      mobileError: "",
      email: "",
      emailError: "",
      message: "",
      time: moment(),
      guests: {label: "2", value: "2"},
      loading: false,
      sent: false
    }
  }
  handleNameChange = e => {
    this.setState({ name: e.target.value })
  }
  handleEmailChange = e => {
    this.setState({ email: e.target.value })
  }
  handleNumberChange = e => {
    if(e.target.value.length <= 10 && /^\d*$/.test(e.target.value)) {
      this.setState({ mobile: e.target.value })
    }
  }
  handleDatetimeChange = e => {
    this.setState({time: e})
  }
  handleMessageChange = e => {
    this.setState({ message: e.target.value })
  }
  handleGuestChange = guests => {
    this.setState({ guests })
  }
  sendEmail = () => {
    const {
      name,
      mobile,
      email,
      time,
      message,
      guests
    } = this.state;
    if(this.validateFields(name, mobile, email)) {
      this.setState({ loading: true })
      emailjs.send("default_service", "template_hbOYPtrO", { name, mobile, time: time.format("LLL"), message, email, guests: guests.value }, process.env.REACT_APP_EMAIL_USER_ID)
        .then(res => {
          this.setState({ sent: true, loading: false })
          console.log(res.status, res.text)
        }, err => {
          console.log(err)
        })
    } else {
      return null
    }
  }
  validateFields = (name, mobile, email) => {
    this.setState({
      nameError: name.length > 0 ? "" : "Enter your name", 
      mobileError: /\d{10}/.test(mobile) ? "" : "Enter your 10 digit phone number", 
      emailError: regex.test(email) ? "" : "Enter a valid email address"
    })
    return name.length > 0 && /\d{10}/.test(mobile) && regex.test(email)
  }

  render() {
    const {
      name,
      mobile,
      email,
      time,
      message,
      guests,
      nameError,
      mobileError,
      emailError,
      loading,
      sent
    } = this.state;
    return (
      <div className="reserve">
        {/* <h3>Make a reservation</h3> */}
        <a 
          target="_blank"
          style={{
            backgroundColor: '#006aff',
            border: 'none',
            color: 'white',
            height: '40px',
            textTransform: 'uppercase',
            fontFamily: 'Square Market, sans-serif',
            letterSpacing: '1px',
            lineHeight: '38px',
            padding: '0 28px',
            borderRadius: '8px',
            fontWeight: '500',
            fontSize: '14px',
            cursor: 'pointer',
            display: 'inline-block'
          }}
          href="https://squareup.com/appointments/book/irmxmolrq6ttrb/L2072RS7486MH/start"
          rel="nofollow"
        >
          Book Now
        </a>
        {/* {loading ? <Infinity /> : null} */}
        {/* {sent ? 
        <div className="reservation-sent">
          <p>Your reservation request has been sent.</p>
          <p>We'll be in touch soon!</p>
          <p>Meanwhile, you can checkout our <a href="/#menu">Menu</a>!</p>
        </div> : loading ? <Infinity /> :
        <div className="reservation-form">
          <p>Please fill out the following form and we will contact you to confirm the reservation.</p>
          <label>Name</label>
          <input className="name-input" value={name} onChange={this.handleNameChange} onBlur={() => this.setState({nameError: name.length > 0 ? "" : "Please enter your name"})} />
          <p className="error-message">{nameError}</p>
          <label>Number of Guests</label>
          <Select className="guest-count" placeholder="2" options={options} onChange={this.handleGuestChange} value={guests} />
          {guests.value === "8+" ? <p>Whoa</p> : null}
          <label>Reservation Time</label>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DateTimePicker 
              className="datetime-input" 
              value={time} 
              onChange={this.handleDatetimeChange} 
              minDate={moment()}
              minDateMessage="Cannot reserve in the past"
            />
          </MuiPickersUtilsProvider>
          <label>Phone Number</label>
          <input className="mobile-input" value={mobile} onChange={this.handleNumberChange} onBlur={() => this.setState({ mobileError: /\d{10}/.test(mobile) ? "" : "Please enter your 10 digit phone number"})} />
          <p className="error-message">{mobileError}</p>
          <label>Email</label>
          <input className="email-input" value={email} onChange={this.handleEmailChange} onBlur={() => this.setState({emailError: regex.test(email) ? "" : "Please enter a valid email address"})} />
          <p className="error-message">{emailError}</p>
          <label>Reservation Details (optional)</label>
          <textarea className="message-input" value={message} onChange={this.handleMessageChange} />
        </div>}
        {sent || loading ? null : 
        <button className="send-email"
          onClick={this.sendEmail}
        >
          Reserve
        </button>} */}
      </div>
    )
  }
}

export default Reserve;
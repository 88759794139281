import React from 'react';
import pbf from '../../images/pbf.jpg'

const Fries = () => {
  return (
    <div className="fries-container">
      <div className="header-img">
        <img src={pbf} />
      </div>
      <div className="heading">Fries</div>
      <div className="main-contents">
        {contents.map(dish => {
          return (
            <div className="main-item">
              <p>{dish.name} {dish.price}</p>
              <p className="description">{dish.description.map(desc => <span>{desc}</span>)}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const contents = [
  {
    name: 'Butter Chicken Waffle Fries',
    description: ['Crispy waffle fries with tender butter chicken on top'],
    price: '12.99',
    img: ''
  },
  {
    name: 'Pork Belly Fries',
    description: ['Crispy fries with fresh sour cream, cilantro, green onions, tomatoes, topped with pork belly and spicy mayo'],
    price: '12.99',
    img: ''
  },
  {
    name: 'Limitless Fries',
    description: ['Fresh veggies topped with cheese, sour cream and spicy mayo'],
    price: '9.99',
    img: ''
  },
  {
    name: 'Flavoured Fries',
    description: ['All Dressed, White Cheddar, Canadian Cheddar, Dill Pickle'],
    price: '5.99',
    img: ''
  },
  {
    name: 'Waffle Fries',
    description: [''],
    price: '5.99',
    img: ''
  },
  {
    name: 'Crispy Fries',
    description: [''],
    price: '5.25',
    img: ''
  },
  {
    name: 'Extra Toppings',
    description: [
      'Pork Belly 3',
      'Crispy Chicken  3',
      'Grilled Chicken 3',
      'Angus Cheese Burger 3',
      'Beef 3'
    ],
    price: '',
    img: ''
  }
]

export default Fries;
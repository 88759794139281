import React from 'react';
import wings from '../../images/wings.jpg'

const Buckets = () => {
  return (
    <div className="buckets-container">
      <div className="heading">Bucket Deals</div>
      <div className="bucket-contents">
        {contents.map((dish, index) => {
          return (
            <div className="bucket-item" key={index}>
              <p>{dish.name} {dish.price}</p>
              <p className="description">{dish.description.map((desc, index) => <span key={index}>{desc}</span>)}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const contents = [
  {
    name: '4 Domestic with a choice of 1 lb Wings OR Pork Belly Fries OR 6 BBQ Skewers',
    description: [''],
    price: '30.00',
    img: ''
  },
  {
    name: '4 Import with a choice of 1 lb Wings OR Pork Belly Fries OR 6 BBQ Skewers',
    description: [''],
    price: '34.00',
    img: ''
  },
]

export default Buckets;
import React, { Fragment, useState, useEffect } from 'react';
import QRCode from 'qrcode.react'
import _ from 'lodash'
import { getMenu } from '../../api/ProductAPI'
import { submitOrder } from '../../api/OrderAPI'
import { Link } from 'react-router-dom'
import ScrollSpyTabs from './ScrollSpyTabs'
import Section from './Section'
import ProductDetails from './ProductDetails'
import Cart from './Cart'
import './index.scss'
const Order = ({ viewCart }) => {
  const [error, setError] = useState(null);
  const [customizing, setCustomizing] = useState(false);
  const [product, setProduct] = useState(null);
  const [menu, setMenu] = useState([]);
  const [isLoaded, setIsLoaded] = useState(menu && menu.length > 0);
  const [cart, setCart] = useState({})
  const [submitted, setSubmitted] = useState(false)
  // find way to access query string for station id


  const handleFetchMenu = menu => {
    setMenu(menu)
    setIsLoaded(true)
  }

  const addToCart = item => {
    let updatedCart = Object.assign({}, cart)
    if(updatedCart[item.id] && updatedCart[item.id].find(sc => _.isEqual(sc.selectedCustomizations, item.selectedCustomizations))) {
      updatedCart[item.id].find(i => _.isEqual(i.selectedCustomizations, item.selectedCustomizations)).quantity++
    } else if(updatedCart[item.id]) {
      updatedCart[item.id].push({
        id: item.id,
        name: item.name,
        description: item.description,
        quantity: 1,
        price: item.price,
        customizedPrice: item.customizedPrice,
        selectedCustomizations: item.selectedCustomizations
      })
    } else {
      updatedCart[item.id] = [{
        id: item.id,
        name: item.name,
        description: item.description,
        quantity: 1,
        price: item.price,
        customizedPrice: item.customizedPrice,
        selectedCustomizations: item.selectedCustomizations
      }]
    }
    setCart(updatedCart)
    const stringCart = JSON.stringify(updatedCart)
    localStorage.setItem('cart', stringCart)
  }

  const removeFromCart = id => {
    let updatedCart = Object.assign({}, cart)
    updatedCart[id] && updatedCart[id].quantity > 1 ? updatedCart[id].quantity-- : delete updatedCart[id]
    setCart(updatedCart)
    const stringCart = JSON.stringify(updatedCart)
    localStorage.setItem('cart', stringCart)
  }

  const prepareCart = cart => {
    let checkoutCart = Object.values(cart).flat()
    console.log(checkoutCart)
    checkoutCart.forEach(item => {
      item.selectedCustomizations = Object.values(item.selectedCustomizations)
    })
    console.log(checkoutCart)
    return checkoutCart
  }

  const handleSubmitOrder = () => {
    const body = {
      products: prepareCart(cart),
      station_id: '70e79356-f10e-4390-b722-674f918033b1',
      branch_id: process.env.REACT_APP_BRANCH_ID
    }
    submitOrder(body, handleOrderSubmission)
  }

  const handleOrderSubmission = success => {
    if(success) {
      setSubmitted(true)
    }
  }

  useEffect(() => {
    getMenu(handleFetchMenu)
    let localCart = JSON.parse(localStorage.getItem('cart'))
    if(localCart) setCart(localCart)
  }, [])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className='shop-index'>
        {customizing ? null :
        <Cart 
          cart={cart} 
          setCart={setCart}
          submitted={submitted}
          handleSubmitOrder={handleSubmitOrder}
          removeFromCart={removeFromCart}
          view={viewCart}
        />}
        {/* <div className='category-navbar'>
        {Object.keys(menu).map((cat, index) => {
          return <div className='category-navbar__category' key={index}>{cat}</div>
        })}
        </div> */}
        {viewCart || customizing ? null : 
        <div className={`product-menu`}>
          <ScrollSpyTabs
            tabsInScroll={
              Object.keys(menu).map((cat, index) => {
                return {
                  text: cat,
                  component: (<Section 
                    key={index} 
                    items={menu[cat]} 
                    cart={cart}
                    addToCart={addToCart}
                    removeFromCart={removeFromCart}
                    setCustomizing={setCustomizing}
                    setProduct={setProduct}
                  />)
                }
              })
            } 
          />
        </div>}
        {customizing ? 
        <ProductDetails
          product={product}
          setCustomizing={setCustomizing}
          setProduct={setProduct}
          addToCart={addToCart}
          removeFromCart={removeFromCart}
        /> : null}
      </div>
    );
  }
}

export default Order
import React from 'react';
import wings from '../../images/wings.jpg'

const Pulutan = () => {
  return (
    <div className="pulutan-container">
      <div className="header-img">
        <img src={wings} />
      </div>
      <div className="heading">Munchies</div>
      <div className="main-contents">
        {contents.map((dish, index) => {
          return (
            <div className="main-item" key={index}>
              <p>{dish.name} {dish.price}</p>
              <p className="description">{dish.description.map((desc, index) => <span key={index}>{desc}</span>)}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const contents = [
  {
    name: 'Chicken Fingers and Fries',
    description: [''],
    price: '7.99',
    img: ''
  },
  {
    name: 'Chicken Wings 1lb',
    description: ['Super crispy golden Chicken wings - $22.99 for 2lbs', 'Maple Chipotle, Sweet Thai, Smoked BBQ, House Hot Sauce, Spicy Ranch, Honey Garlic, Dry Mango Chipotle, Salt & Pepper, Sweet Fusion', 'Hot BBQ', 'Hot Creamy Parmesan'],
    price: '12.99',
    img: ''
  }
]

export default Pulutan;
import React from 'react';
import pcwr from '../../images/pcwr.jpg'

const Mains = () => {
  return (
    <div className="mains-container">
      <div className="header-img">
        <img src={pcwr} />
      </div>
      <div className="heading">Entrées</div>
      <div className="main-contents">
        <p>Items with rice can be substituted with garlic rice for $2</p>
        {contents.map((dish, index) => {
          return (
            <div className="main-item" key={index}>
              <p>{dish.name} {dish.price}</p>
              <p className="description">{dish.description.map((desc, index) => <span key={index}>{desc}</span>)}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const contents = [
  {
    name: 'Crispy Pork Cutlet',
    description: [''],
    price: '14.99',
    img: ''
  },
  {
    name: 'Crispy Pork Belly (Bagnet)',
    description: [''],
    price: '15.99',
    img: ''
  },
  {
    name: 'Adobo Lemongrass Chicken Plate',
    description: ['Served with rice and vegetables'],
    price: '13.99',
    img: ''
  },
  {
    name: 'Pork Belly Plate',
    description: ['Served with rice and vegetables'],
    price: '13.99',
    img: ''
  },
  {
    name: 'Pork Chop Plate',
    description: ['Served with rice and vegetables'],
    price: '13.99',
    img: ''
  },
  {
    name: 'Skewer Plate',
    description: ['Choice of 6 skewers either chicken, pork or mix'],
    price: '13.99',
    img: ''
  },
  {
    name: 'Beef Short Rib Plate',
    description: ['Beef short ribs marinated in our sweet house sauce served with rice and side salad'],
    price: '16.99',
    img: ''
  },
  {
    name: 'Grilled Platter',
    description: ['Choice of 2 proteins: ', 'Adobo Lemongrass Chicken, Pork Belly, Pork Chop, 3 BBQ Skewers (chicken or pork)'],
    price: '13.99',
    img: ''
  },
  {
    name: 'Limitless Platter',
    description: ['Adobo Lemongrass Chicken Thigh, BBQ Skewers (3), Pork Belly & Pork Chop'],
    price: '36.99',
    img: ''
  },
  {
    name: 'Limitless Mania',
    description: ['Limitless Mania comes with Limitless platter, 1lb of wings, cheese corn and a sides of rice or fries'],
    price: '47.99',
    img: ''
  },
  {
    name: 'Chicken Fingers (4) and Fries',
    description: ['Chicken fingers tossed in your choice of sauce and served with a side of crispy fries'],
    price: '11.99',
    img: ''
  },
  {
    name: 'Chicken Wings and Fries',
    description: ['Super crispy golden Chicken wings', 'Buffalo, Maple Chipotle, Sweet Thai, Smoked BBQ, House Hot Sauce, Spicy Ranch, Honey Garlic, Dry Mango Chipotle, Salt & Pepper, Sweet Fusion, Hot BBQ, Hot Creamy Parmesan'],
    price: '12.99',
    img: ''
  },
  {
    name: 'Garlic Rice',
    description: [''],
    price: '3.00',
    img: ''
  }
  // {
  //   name: '',
  //   description: [''],
  //   price: '',
  //   img: ''
  // },
  // {
  //   name: '',
  //   description: [''],
  //   price: '',
  //   img: ''
  // },
]

export default Mains;
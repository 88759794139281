import React, { Fragment, useState, useEffect } from 'react';

const Section = ({ items, cart, addToCart, removeFromCart, setCustomizing, setProduct }) => {
  const showItemDetails = item => {
    setCustomizing(true)
    setProduct(item)
  }
  return (
    <div className='product-menu__section'>
      {items.map((item, index) => {
        return (
          <div className='product-menu__item' key={index} onClick={() => showItemDetails(item)}>
            <div className='item-panel'>
              <div className='item-panel__left'>
                <div>{item['name']}</div>
                <div>{item['price']}</div>
              </div>
              <div className='item-panel__right'>
                {cart[item['id']] ? <div className='item-quantity'>{cart[item['id']].reduce((s, i) => s + i.quantity, 0)}</div> : null}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Section
import React, { Component } from 'react';
import './index.scss';


const NotFound = () => {
  return (
    <div className="not-found">
      <h3>The page you have requested could not be found!</h3>
    </div>
  )
}

export default NotFound;
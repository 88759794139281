import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { login, getCurrentUser } from '../../api/AuthAPI';
import './index.scss';

const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

const Login = props => {
  const [loginState, setLoginState] = useState(false)
  const { register, handleSubmit, errors } = useForm(); // initialize the hook
  const [user, setUser] = useState(null)
  const onSubmit = (data) => {
    login(data, setLoginState);
  };

  const getUser = async () => {
    const currentUser = await getCurrentUser()
    if(currentUser) {
      setUser(currentUser)
    }
  }

  getUser()

  if(user) {
    return <Redirect to={props.location.state && props.location.state.referrer || '/'} />
  }

  return (
    <div className='login'>
      <form className='form-container' onSubmit={handleSubmit(onSubmit)}>
        {/* <div className='form-input'> */}
          <input placeholder='Email' className='form-input' name="email" ref={register({ pattern: EMAIL_REGEX })} /> {/* register an input */}
          {errors.email && 'Email format incorrect.'}
        {/* </div> */}
        {/* <div className='form-inpput'> */}
          <input placeholder='********' className='form-input' name="password" type="password" ref={register({ required: true })} />
          {errors.password && 'Password is required.'}
        {/* </div> */}
        {/* <div className='form-submit'> */}
          <input className='form-submit' type="submit" />
        {/* </div> */}
        <div style={{marginBottom: '2em'}}></div>
      </form>
    </div>
  )
};

export default Login;

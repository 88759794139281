import React from 'react';
import pbn2 from '../../images/pbn2.jpg'

const Starters = () => {
  return (
    <div className="starters-container">
      <div className="header-img">
        <img src={pbn2} />
      </div>
      <div className="heading">Starters</div>
      <div className="starter-contents">
        {contents.map((dish, index) => {
          return (
            <div className="starter-item" key={index}>
              <p>{dish.name} {dish.price}</p>
              <p className="description">{dish.description.map((desc, index) => <span key={index}>{desc}</span>)}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const contents = [
  {
    name: 'Spring Roll (Lumpia Shanghai)',
    description: [''],
    price: '5.49',
    img: ''
  },
  {
    name: 'BBQ Skewers (3)',
    description: ['Chicken or Pork', 'Upsize to 9 skewers for $12.99'],
    price: '5.49',
    img: ''
  },
  {
    name: 'Ultimate Nachos',
    description: ['Organic tortilla chips with sour cream, jalapeños, cilantro, green onions, tomatoes and cheese with salsa on the side', 'Add pork belly strips $3, add chicken tenders $4'],
    price: '10.99',
    img: ''
  },
  {
    name: 'Onion Rings',
    description: ['Crispy deep fried onion rings'],
    price: '5.99',
    img: ''
  },
  {
    name: 'Takoyaki',
    description: ['Octopus ball made with a wheat flour-based batter filled with minced octopus, tempura scraps, pickled ginger, and green onion'],
    price: '7.99',
    img: ''
  },
  {
    name: 'Cheese Corn',
    description: ['Delicious gooey melted cheese on sweet corn'],
    price: '6.99',
    img: ''
  },
  {
    name: 'Street Tacos (3)',
    description: ['Chicken or Pork', 'Beef $3'],
    price: '11.99',
    img: ''
  }
]

export default Starters;
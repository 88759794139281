import React, { Fragment, useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const ProductDetails = ({ product, setCustomizing, setProduct, addToCart, removeFromCart }) => {
  const [customizations, setCustomizations] = useState({})

  const handleCheck = (e, customization) => {
    if(e.target.checked) {
      addCustomization(customization)
    } else {
      removeCustomization(customization)
    }
    console.log(customizations[customization.id])
  }

  const handleRadio = (e, customization) => {
    // NEED TO REFACTOR THIS TO STORE IN A SEPARATE STATE AND CONSOLIDATE ON SUBMIT
  }

  const addCustomization = customization => {
    let updatedCustomizations = customizations
    updatedCustomizations[customization.id] = customization
    setCustomizations(updatedCustomizations)
  }

  const removeCustomization = customization => {
    let updatedCustomizations = customizations
    delete updatedCustomizations[customization.id]
    setCustomizations(updatedCustomizations)
  }
  
  const handleAddProduct = product => {
    let customizedProduct = product
    let customizationPrice = Object.values(customizations).reduce((sum, c) => sum + parseFloat(c.price) * 100, 0) / 100
    customizedProduct.selectedCustomizations = customizations
    customizedProduct.customizedPrice = parseFloat(product.price) + customizationPrice
    addToCart(customizedProduct)
    setCustomizing(false)
    setProduct(null)
  }
  
  return (
    <div className='product-details'>
      {product.name}
      {Object.keys(product.customizations).map((grouping, index) => {
        if(product.customizations[grouping].exclusive) {
          return (
            // WIP
            <FormControl component="fieldset">
              <FormLabel component="legend">{grouping}</FormLabel>
              <RadioGroup
                aria-label={grouping}
                name="controlled-radio-buttons-group"
                value={product.customizations[grouping].value}
                onChange={e => handleRadio(e)}
              >
                {product.customizations[grouping].options.map((option, idx) => {
                  return <FormControlLabel value={option.id} control={<Radio />} label={`${option.name} ${option.price > 0 ? option.price : ''}`} />
                })}
              </RadioGroup>
            </FormControl>
          )
        } else {
          return (
            <div key={index}>
              <h4>{grouping}</h4>
              {product.customizations[grouping].options.map((option, idx) => {
                return (
                  <div key={idx}>
                    <Checkbox checked={customizations[option.id]} onChange={e => handleCheck(e, option)} />
                    <span>{option.name}</span>
                    <span>{option.price}</span>
                  </div>
                )
              })}
            </div>
          )
        }
      })}
      <button onClick={() => {
        setCustomizing(false)
        setProduct(null)
      }}>Close</button>
      <div className='cart-actions'>
        <div className='add-to-cart' onClick={() => handleAddProduct(product)}>Add</div>
      </div>
    </div>
  )
}

export default ProductDetails
import React, { Fragment, useState, useEffect } from 'react';
import QRCode from 'qrcode.react'
import { getStations, createStation, updateStation } from '../../api/StationAPI'
import './index.scss'
const Station = ({ match }) => {
  const [stations, setStations] = useState([])
  const [loading, setLoading] = useState(true)
  const [parameters, setParameters] = useState(null)
  const [errors, setErrors] = useState([])
  console.log(match)
  const uuid = match.params.uuid

  const handleFetchStations = stations => {
    setStations(stations)
    setLoading(false)
  }

  const handleCreateStation = () => {
    setLoading(true)
    createStation(parameters, () => getStations(handleFetchStations))
  }

  useEffect(() => {
    getStations(handleFetchStations)
    setParameters({
      codename: '4',
      capacity: 6,
      branch_id: process.env.REACT_APP_BRANCH_ID
    })
  }, [])
  if (loading) {
    return (
      <div>loading...</div>
    )
  } else {
    return (
      <div className='stations-index'>
        <div className='station-create' onClick={handleCreateStation}>
          New Station
        </div>
        {stations.map(station => (
          <div className='station-panel' key={station.id}>
            {station.codename} {station.capacity}
            <QRCode value={`https://1f2d2c4ca20d.ngrok.io/order?station_id=${station.id}`} />
          </div>
        ))}
      </div>
    );
  }
}

export default Station
import React from 'react';
import burger from '../../images/burger.jpg'

const Burgers = () => {
  return (
    <div className="burgers-container">
      <div className="header-img">
        <img src={burger} />
      </div>
      <div className="heading">Limitless Burgers</div>
      <p>All burgers come with a side of french fries or coleslaw, +$2.00 substitute for onion rings</p>
      <div className="main-contents">
        {contents.map((dish, index) => {
          return (
            <div className="main-item" key={index}>
              <p>{dish.name} {dish.price}</p>
              <p className="description">{dish.description.map((desc, index) => <span key={index}>{desc}</span>)}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const contents = [
  {
    name: 'Limitless Mega Burger',
    description: ['Grilled beef patty with lettuce, tomatoes, cheese, pickles, burger sauce'],
    price: '13.99',
    img: ''
  },
  {
    name: 'Pork Belly sandwich ',
    description: ['Pork belly topped with coleslaw, served with a side of crispy fries'],
    price: '13.99',
    img: ''
  },
  {
    name: 'Chorizo (Longanisa) Burger',
    description: ['Sweet pork patty with picked vegetables and aioli'],
    price: '13.99',
    img: ''
  },
  {
    name: 'Chicken Burger',
    description: ['Grilled lemongrass chicken with shredded coleslaw, grated cheese blend'],
    price: '13.99',
    img: ''
  },
  {
    name: 'Classic Crispy Chicken Burger',
    description: ['Deep fried chicken thigh fillet on a brioche bun. Served with a side of crispy fries.'],
    price: '13.99',
    img: ''
  }
]

export default Burgers;
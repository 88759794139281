import React, { useState } from 'react';

import "./index.scss";
import hhmenu from "../../images/happy_hour_menu.png";
import menu from "../../images/Limitless_menu.pdf";
import drink from "../../images/drinks_menu.pdf";
import { LinkPreviewer } from "./LinkPreviewer";

// import Section from "./Section.js";
// import pbn2 from "../../images/pbn2.jpg";
// import pbf from "../../images/pbf.jpg";
// import burger from "../../images/burger.jpg";
// import mf from "../../images/mf.jpg";
// import pcwr from "../../images/pcwr.jpg";
// import beer from "../../images/beer.jpg";
// import willy from "../../images/willy.jpg";
// import cvk from "../../images/cvk.jpg";
// import ScrollSpyTabs from "./ScrollSpyTabs";

const Menu = () => {
  return (
    <div className="menu">
      <a target="_blank" href={menu}>
        <h1>Menu</h1>
      </a>
      <a  target="_blank" href={drink}>
        <h1>Drink Menu</h1>
      </a>
      <LinkPreviewer href={hhmenu} image={hhmenu} title={""} text={""}>
        <a target="_blank" href={hhmenu}>
          <h1>Happy Hour Menu</h1>
        </a>
      </LinkPreviewer>
      <h3>Monday-Friday 2:00PM - 6:00PM</h3>
    </div>
  );
};

export default Menu;

// const Menu = () => {
//   return (
//     <div className="menu">
//       <div className={`product-menu`}>
//         <ScrollSpyTabs
//           tabsInScroll={
//             menuContents.map((cat, index) => {
//               return {
//                 text: cat.name,
//                 component: (<Section
//                   key={index}
//                   contents={cat}
//                 />)
//               }
//             })
//           }
//         />
//       </div>
//     </div>
//   )
// }

// const menuContents = [
//   {
//     type: 'specials',
//     name: 'Specials',
//     image: null,
//     notes: 'Items with rice can be substituted with garlic rice for $2',
//     items: [
//       {
//         name: 'Monday - Chicken Wings',
//         description: [''],
//         price: '10.00',
//         img: ''
//       },
//       {
//         name: 'Tuesday - Pork Chop with Rice',
//         description: [''],
//         price: '10.00',
//         img: ''
//       },
//       {
//         name: 'Wednesday - Chicken Thigh with Rice',
//         description: [''],
//         price: '10.00',
//         img: ''
//       },
//       {
//         name: 'Thursday - Pork Belly with Rice',
//         description: [''],
//         price: '10.00',
//         img: ''
//       },
//       {
//         name: 'Friday - Three Dollar Shots',
//         description: [''],
//         price: '3.00',
//         img: ''
//       },
//       {
//         name: 'Saturday - Three Dollar Shots',
//         description: [''],
//         price: '3.00',
//         img: ''
//       },
//       {
//         name: 'Sunday - All Cocktails',
//         description: [''],
//         price: '7.00',
//         img: ''
//       },
//     ],
//   },
//   {
//     type: 'happy_hour',
//     name: 'Happy Hour',
//     image: null,
//     notes: ['Dine-in only', 'Monday to Friday', '2pm - 6pm'],
//     four: [
//       {
//         name: 'Shots',
//         description: [''],
//         img: ''
//       }
//     ],
//     five: [
//       {
//         name: 'Spring Roll (Lumpia Shanghai)',
//         description: ['Hand rolled filipino pork spring rolls'],
//         img: ''
//       },
//       {
//         name: 'Pork Skewers (3)',
//         description: ['House marinated pork skewers brushed with our homemade bbq sauce'],
//         img: ''
//       },
//       {
//         name: 'Chicken Skewers (3)',
//         description: ['House marinated chicken skewers brushed with our homemade bbq sauce'],
//         img: ''
//       },
//       {
//         name: 'Onion Rings',
//         description: ['Deep fried onion rings tossed in mango chipotle seasoning'],
//         img: ''
//       },
//       {
//         name: 'Amsterdam Blonde 16oz',
//         description: ['Lager, Crisp taste with a smooth finish'],
//         img: ''
//       },
//       {
//         name: 'Import Beer',
//         description: ['Heineken, Stella Artois, Corona'],
//         img: ''
//       },
//     ],
//     ten: [
//       {
//         name: 'Nachos',
//         description: ['Fresh tortilla chips topped with cheese, jalapenos, tomatoes, cilantro, green onion, and sour cream with salsa'],
//         img: ''
//       },
//       {
//         name: 'Chicken/Pork Taco',
//         description: ['Flour tortilla with tomatoes, green onion, cilantro, cheese, and spicy mayo'],
//         img: ''
//       }
//     ]
//   },
//   {
//     name: 'Starters',
//     image: pbn2,
//     items: [
//       {
//         name: 'Popcorn Chicken',
//         description: [
//           'Dusted - dusted in seasoning of your choice',
//           'Toss Your Pops - toss in sauce of your choice',
//           'Umai Style - japanese style topped with japanese mayo, okonamiyaki sauce, green onion, sesame seed, seaweed and bonito flakes $12.99',
//           'Kaikaze Style - animal style topped with fries, cheese, chicken, signature sauce, BBQ sauce and green onion $12.99',
//           'Northern Style - indian style butter chicken sauce, sour cream, cheese, green onion and cilantro $12.99',
//           'Dynamite - hot ones inspiration mix with our house blend sauce (ask server for hot sauce of the week) $12.99'
//         ],
//         price: '11.99',
//         img: ''
//       },
//       {
//         name: 'BBQ Skewers (3)',
//         description: ['Chicken or Pork', 'Upsize to 9 skewers for $14.99'],
//         price: '6.75',
//         img: ''
//       },
//       {
//         name: 'Ultimate Nachos',
//         description: ['Corn tortilla chips with sour cream, jalapeños, cilantro, green onions, tomatoes and cheese with salsa on the side', 'Add pork belly strips $3, add chicken tenders $4'],
//         price: '12.99',
//         img: ''
//       },
//       {
//         name: 'Onion Rings',
//         description: ['Crispy deep fried onion rings'],
//         price: '5.99',
//         img: ''
//       },
//       {
//         name: 'Takoyaki',
//         description: ['Octopus ball made with a wheat flour-based batter filled with minced octopus, tempura scraps, pickled ginger, and green onion'],
//         price: '9.99',
//         img: ''
//       },
//       {
//         name: 'Cheese Corn',
//         description: ['Delicious gooey melted cheese on sweet corn'],
//         price: '8.99',
//         img: ''
//       },
//       {
//         name: 'Street Tacos (3)',
//         description: ['Chicken or Pork', 'Smoked Jerk Pork', 'Beef $3'],
//         price: '13.99',
//         img: ''
//       }
//     ],
//   },
//   {
//     name: 'Fries',
//     image: pbf,
//     items: [
//       {
//         name: 'Butter Chicken Waffle Fries',
//         description: ['Crispy waffle fries with tender butter chicken on top'],
//         price: '12.99',
//         img: ''
//       },
//       {
//         name: 'Pork Belly Fries',
//         description: ['Crispy fries with fresh sour cream, cilantro, green onions, tomatoes, topped with pork belly and spicy mayo'],
//         price: '12.99',
//         img: ''
//       },
//       {
//         name: 'Limitless Fries',
//         description: ['Fresh veggies topped with cheese, sour cream and spicy mayo'],
//         price: '9.99',
//         img: ''
//       },
//       {
//         name: 'Flavoured Fries',
//         description: ['All Dressed, White Cheddar, Canadian Cheddar, Dill Pickle', 'Salt & Vinegar', 'Cajun', 'Mango Chipotle'],
//         price: '5.99',
//         img: ''
//       },
//       {
//         name: 'Waffle Fries',
//         description: [''],
//         price: '5.99',
//         img: ''
//       },
//       {
//         name: 'Crispy Fries',
//         description: [''],
//         price: '5.25',
//         img: ''
//       },
//       {
//         name: 'Extra Toppings',
//         description: [
//           'Pork Belly 3',
//           'Crispy Chicken  3',
//           'Grilled Chicken 3',
//           'Angus Cheese Burger 3',
//           'Beef 3'
//         ],
//         price: '',
//         img: ''
//       }
//     ],
//   },
//   {
//     name: 'Burgers',
//     image: burger,
//     items: [
//       {
//         name: 'Limitless Mega Burger',
//         description: ['Grilled beef patty with lettuce, tomatoes, cheese, pickles, burger sauce'],
//         price: '13.99',
//         img: ''
//       },
//       {
//         name: 'Pork Belly sandwich ',
//         description: ['Pork belly topped with coleslaw, served with a side of crispy fries'],
//         price: '13.99',
//         img: ''
//       },
//       {
//         name: 'Chorizo (Longanisa) Burger',
//         description: ['Sweet pork patty with picked vegetables and aioli'],
//         price: '13.99',
//         img: ''
//       },
//       {
//         name: 'Chicken Burger',
//         description: ['Grilled lemongrass chicken with shredded coleslaw, grated cheese blend'],
//         price: '13.99',
//         img: ''
//       },
//       {
//         name: 'Classic Crispy Chicken Burger',
//         description: ['Deep fried chicken thigh fillet on a brioche bun. Served with a side of crispy fries.'],
//         price: '13.99',
//         img: ''
//       }
//     ],
//   },
//   {
//     name: 'Seafood',
//     image: mf,
//     items: [
//       {
//         name: 'Garlic Butter Shrimp',
//         description: ['Stir-fried shimp with spiced seasoning and garlic butter'],
//         price: '10.99',
//         img: ''
//       },
//       {
//         name: 'Oven Baked Clams',
//         description: ['Fresh clams seasoned with garlic butter then baked to perfection'],
//         price: '11.99',
//         img: ''
//       },
//       {
//         name: 'Grilled Milkfish Plate',
//         description: ['Fresh grilled milkfish tossed in our mixed vegetables and house vinegar topped with Japanese mayo'],
//         price: '14.99',
//         img: ''
//       },
//       {
//         name: 'Land & Sea Platter',
//         description: ['Milkfish, 6 BBQ Pork Skewers'],
//         price: '26.99',
//         img: ''
//       }
//     ],
//   },
//   {
//     name: 'Mains',
//     image: pcwr,
//     items: [
//       {
//         name: 'Stir Fry Garlic Beef',
//         description: ['Stir fried garlic beef cubes served with rice'],
//         price: '15.99',
//         img: ''
//       },
//       {
//         name: 'Crispy Pork Cutlet',
//         description: [''],
//         price: '14.99',
//         img: ''
//       },
//       {
//         name: 'Adobo Lemongrass Chicken Plate',
//         description: ['Served with rice and vegetables'],
//         price: '14.99',
//         img: ''
//       },
//       {
//         name: 'Pork Belly Plate',
//         description: ['Served with rice and vegetables'],
//         price: '14.99',
//         img: ''
//       },
//       {
//         name: 'Pork Chop Plate',
//         description: ['Served with rice and vegetables'],
//         price: '14.99',
//         img: ''
//       },
//       {
//         name: 'Skewer Plate',
//         description: ['Choice of 6 skewers either chicken, pork or mix'],
//         price: '14.99',
//         img: ''
//       },
//       {
//         name: 'Beef Short Rib Plate',
//         description: ['Beef short ribs marinated in our sweet house sauce served with rice and side salad'],
//         price: '16.99',
//         img: ''
//       },
//       {
//         name: 'Grilled Platter',
//         description: ['Choice of 2 proteins: ', 'Adobo Lemongrass Chicken, Pork Belly, Pork Chop, 3 BBQ Skewers (chicken or pork)'],
//         price: '14.99',
//         img: ''
//       },
//       {
//         name: 'Limitless Platter',
//         description: ['Adobo Lemongrass Chicken Thigh, BBQ Skewers (3), Pork Belly & Pork Chop'],
//         price: '39.99',
//         img: ''
//       },
//       {
//         name: 'Limitless Mania',
//         description: ['Limitless Mania comes with Limitless platter, 1lb of wings, cheese corn and a sides of rice or fries'],
//         price: '52.99',
//         img: ''
//       },
//       {
//         name: 'Chicken Fingers (4) and Fries',
//         description: ['Chicken fingers tossed in your choice of sauce and served with a side of crispy fries'],
//         price: '12.99',
//         img: ''
//       },
//       {
//         name: 'Chicken Wings and Fries',
//         description: ['Super crispy golden Chicken wings', 'Buffalo, Maple Chipotle, Sweet Thai, Smoked BBQ, House Hot Sauce, Spicy Ranch, Honey Garlic, Dry Mango Chipotle, Salt & Pepper, Sweet Fusion, Hot BBQ, Hot Creamy Parmesan'],
//         price: '14.99',
//         img: ''
//       },
//       {
//         name: 'Garlic Rice',
//         description: [''],
//         price: '3.00',
//         img: ''
//       }
//     ],
//   },
//   {
//     name: 'Beers',
//     image: beer,
//     items: [
//       {
//         name: 'Domestic (341ml)',
//         description: ['Canadian', 'Budweiser', 'Coors Light'],
//         price: '4.49',
//         img: ''
//       },
//       {
//         name: 'Import (330ml)',
//         description: ['Corona', 'Heineken', 'Stella Artois'],
//         price: '5.49',
//         img: ''
//       },
//       {
//         name: 'Asahi (16oz)',
//         description: [''],
//         price: '7.99',
//         img: ''
//       },
//       {
//         name: 'Asahi (64oz)',
//         description: [''],
//         price: '20.99',
//         img: ''
//       },
//       {
//         name: 'Amsterdam Blonde (can)',
//         description: [''],
//         price: '6.50',
//         img: ''
//       },
//       {
//         name: 'Amsterdam Speed (can)',
//         description: [''],
//         price: '6.50',
//         img: ''
//       },
//       {
//         name: 'Amsterdam Blonde (16oz)',
//         description: [''],
//         price: '5.50',
//         img: ''
//       },
//       {
//         name: 'Amsterdam Blonde (32oz)',
//         description: [''],
//         price: '11.99',
//         img: ''
//       },
//       {
//         name: 'Amsterdam Blonde (64oz)',
//         description: [''],
//         price: '17.99',
//         img: ''
//       }
//     ],
//   },
//   {
//     name: 'Soju (Bottle)',
//     image: null,
//     items: [
//       {
//         name: "Chum Churum Rich, Peach, Strawberry, Apple, Yogurt",
//         description: [''],
//         price: '17.99',
//         img: ''
//       }
//     ],
//   },
//   {
//     name: 'Wines',
//     image: null,
//     items: [
//       {
//         name: "Jacob's Creek Moscato (7oz)",
//         description: [''],
//         price: '7.00',
//         img: ''
//       },
//       {
//         name: "Jacob's Creek Moscato (750ml bottle)",
//         description: [''],
//         price: '24.99',
//         img: ''
//       },
//       {
//         name: "Jackson-Triggs Merlot (7oz)",
//         description: [''],
//         price: '7.00',
//         img: ''
//       },
//       {
//         name: "Jackson-Triggs Merlot (750ml bottle)",
//         description: [''],
//         price: '24.99',
//         img: ''
//       }
//     ],
//   },
//   {
//     name: 'Cocktails',
//     image: willy,
//     items: [
//       {
//         name: 'Beach Please',
//         description: ['Peach Schnapps, vodka, orange juice, cranberry juice'],
//         price: '8.99',
//         img: ''
//       },
//       {
//         name: 'Tropical Tea',
//         description: ['Mango, crown royal, and lemon tea'],
//         price: '8.99',
//         img: ''
//       },
//       {
//         name: 'Calamansi Whiskey Sour',
//         description: ['Calamansi, crown royal, and lemon lime soda'],
//         price: '8.99',
//         img: ''
//       },
//       {
//         name: 'Chilly Willy',
//         description: ['Bacardi superior white rum mixed with blue curacao, pineapple juice, topped with lemon lime soda'],
//         price: '8.99',
//         img: ''
//       },
//       {
//         name: 'Lychee Smash',
//         description: ['Fresh mint and lychee muddled in a cup, mixed with gin & topped with lemon lime soda'],
//         price: '8.99',
//         img: ''
//       },
//       {
//         name: 'Mint Mojito',
//         description: ['Fresh mint stirred with sugar and mixed with barcardi white rum, lemon lime soda and water'],
//         price: '8.99',
//         img: ''
//       },
//       {
//         name: 'Pinay Colada',
//         description: ['White rum, pina colada mixture blended with ice'],
//         price: '9.49',
//         img: ''
//       },
//       {
//         name: 'Strawberry Daiquiri',
//         description: ['Blended strawberry with lime juice'],
//         price: '9.49',
//         img: ''
//       },
//       {
//         name: 'Mango Daiquiri',
//         description: ['Blended Mangoes with lime juice'],
//         price: '9.49',
//         img: ''
//       },
//       {
//         name: 'Ginie in a Bottle',
//         description: ['Empress Gin, muddled raspberry, calamansi honey, pineapple juice, and tonic water'],
//         price: '13.99',
//         img: ''
//       },
//       {
//         name: 'Honeydew Me',
//         description: ['Melon Liquer, Vodka, orange juice, grenadine'],
//         price: '9.99',
//         img: ''
//       }
//     ],
//   },
//   {
//     name: 'Liquors',
//     image: null,
//     items: [
//       {
//         name: 'Shots (1oz)',
//         description: ['St Remy XO', 'Jack Daniels', 'Crown Royal', 'Captain Morgan', 'Jose Cuervo', 'Beefeater', 'Jägermeister', 'Kraken', 'JP Wiser', 'Jameson'],
//         price: '5.00',
//         img: ''
//       },
//       {
//         name: 'Premium Shots (1oz)',
//         description: ['Don Julio Resposado $13', 'Patron $11', 'Hennessy VS - $8', 'Glenfiddich 12yr $8', 'Suntory Toki $7', 'Empress 1908 Gin $7', 'Grand Marnier $7', 'Johnny Walker Black Label - $7', 'Grey Goose - $7', 'Courvoisier VS Cognac - $7', 'Hendrick\'s Gin - $7'],
//         price: '',
//         img: ''
//       }
//     ],
//   },
//   {
//     name: 'Bomb Shots',
//     image: cvk,
//     items: [
//       {
//         name: 'Jägerbomb (1oz)',
//         price: '6.99',
//         description: [],
//         img: ''
//       },
//       {
//         name: 'Beergin (1oz)',
//         price: '5.99',
//         description: [],
//         img: ''
//       },
//       {
//         name: 'Kraken in the Deep (1oz)',
//         price: '5.99',
//         description: [],
//         img: ''
//       },
//       {
//         name: 'Captain vs Kraken (2oz)',
//         price: '10.99',
//         description: [],
//         img: ''
//       }
//     ],
//   }
// ]

import React from 'react';
import moment from 'moment'

const day = moment().day()

const Specials = () => {
  return (
    <div className="specials-container">
      <div className="heading">Daily Specials</div>
      <div className="special-contents">
        {contents.map((dish, i) => {
          return (
            <div key={i}>
              <p className={i + 1 === day ? "special-item today" : "special-item"}>{dish.name} {dish.price}</p>
              <p className="description">{dish.description.map((desc, index) => <span key={index}>{desc}</span>)}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const contents = [
  {
    name: 'Monday - Chicken Wings',
    description: [''],
    price: '10.00',
    img: ''
  },
  {
    name: 'Tuesday - Pork Chop with Rice',
    description: [''],
    price: '10.00',
    img: ''
  },
  {
    name: 'Wednesday - Chicken Thigh with Rice',
    description: [''],
    price: '10.00',
    img: ''
  },
  {
    name: 'Thursday - Pork Belly with Rice',
    description: [''],
    price: '10.00',
    img: ''
  },
  {
    name: 'Friday - Three Dollar Shots',
    description: [''],
    price: '3.00',
    img: ''
  },
  {
    name: 'Saturday - Three Dollar Shots',
    description: [''],
    price: '3.00',
    img: ''
  },
  {
    name: 'Sunday - All Cocktails',
    description: [''],
    price: '5.00',
    img: ''
  },
]

export default Specials;
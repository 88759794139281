const getStations = (callback) => {
  fetch(`${process.env.REACT_APP_API_URL}/stations?branch_id=${process.env.REACT_APP_BRANCH_ID}`)
    .then(res => res.json())
    .then(
      (result) => {
        callback(result)
      },
      (error) => {
        
      }
    )
}

const createStation = (body, callback) => {
  const params = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }
  fetch(`${process.env.REACT_APP_API_URL}/stations?branch_id=${process.env.REACT_APP_BRANCH_ID}`, params)
    .then(res => res.json())
    .then(
      (result) => {
        callback()
      },
      (error) => {
        
      }
    )
}

const updateStation = (stationId, body, callback) => {
  const params = {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body
  }
  fetch(`${process.env.REACT_APP_API_URL}/stations/${stationId}`)
    .then(res => res.json())
    .then(
      (result) => {
        callback(result)
      },
      (error) => {
        
      }
    )
}

const deleteStation = (stationId, callback) => {
  const params = {
    method: 'DELETE'
  }
  fetch(`${process.env.REACT_APP_API_URL}/stations/${stationId}`, params)
    .then(res => res.json())
    .then(
      (result) => {
        callback(result)
      },
      (error) => {
        
      }
    )
}

export {
  getStations,
  createStation,
  updateStation,
  deleteStation
}
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { logout } from '../../api/AuthAPI';
import './index.scss';

const Logout = props => {
  // const [logoutState, setLogoutState] = useState(false)
  useEffect(() => {
    logout(() => {});
  }, [])
  return <Redirect to={'/'} />
};

export default Logout;

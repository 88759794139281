import React, { Component } from 'react';
import './index.scss';


const About = () => {
  return (
    <div className="about">
      <h3>About Limitless Bar & Grill</h3>
      <p>We are an up and coming new restaurant that specializes in Filipino & Chinese barbecue foods.</p>
      <p>Being food enthusiasts, our ambition is to serve delicious food for affordable pricing.</p>
      <p>Our aim is to provide an unpretentious and relaxing atmosphere for our customers and make every moment memorable.</p>
      <strong>"Eat at your limit for less"</strong>
      <strong>"be LIMITLESS"</strong>
    </div>
  )
}

export default About;

const submitOrder = (order, callback) => {
  const body = JSON.stringify(order)
  const params = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body
  }
  fetch(`${process.env.REACT_APP_API_URL}/orders`, params)
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result)
        callback(true)
      },
      (error) => {
        
      }
    )
}

export {
  submitOrder
}
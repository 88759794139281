import React, { useState, useEffect } from 'react';
import eventjpg from '../../images/event_modal.jpg';
import './ModalComp.scss'

const Modal = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const modalStyle = {
    position: 'fixed',
    zIndex: 4,
    left: 0,
    top: 1,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  };

  const contentStyle = {
    display:'flex',
    flexDirection:'column',
    position: 'relative',
    alignItems:'center',
    justifyContent:'center',
    transform: 'translate(0%, 5%)',
    padding: '20px',
    borderRadius: '8px',
    maxWidth: '100%',
    textAlign: 'center',
  };

  const imageStyle = {
    maxWidth: '1500px',
    maxHeight: '1000px',
    height: '800px',
    userSelect: 'none',
  };

  const closeButtonStyle = {
    position: 'relative',
    cursor: 'pointer',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#888',
    border: '2px solid white',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    right: '-250px'
  };

  // useEffect(() => {
  //   const handleResize = () => {
  //     // Adjust the button position when the viewport changes
  //     const image = document.getElementById('modal-image');
  //     const button = document.getElementById('modal-close-button');
  //     if (image && button) {
  //       const imageRect = image.getBoundingClientRect();
  //       button.style.top = `${imageRect.top - 200}px`;
  //       button.style.right = `${imageRect.right -500}px`;
  //     }
  //   };

  //   // Attach resize event listener
  //   window.addEventListener('resize', handleResize);

  //   // Cleanup function to remove the event listener
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };r
  // }, []);

  useEffect(() => {
    // Disable scrolling when the modal is open
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = ''; // Restore default value
    }
  
    // Re-enable scrolling when the component is unmounted
    return () => {
      document.body.style.overflow = ''; // Restore default value
    };
  }, [isModalOpen]);
  

  if (!isModalOpen) {
    return null; // Don't render the modal if it's closed
  }

  return (
    <div className="modal" style={modalStyle}>
      <div className="modal-content" style={contentStyle}>
      <span
          style={closeButtonStyle}
          onClick={closeModal}
          id="modal-close-button"
        >
          &times;
        </span>
        <img 
        className= "modal-image" src={eventjpg} alt="Event" style={imageStyle} id="modal-image" />
      </div>
    </div>
  );
};

export default Modal;

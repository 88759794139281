import React, { Component, Fragment } from 'react';
import Logo from "../../images/lbng_store.png"
import Lbng from "../../images/lbng_red.png"
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagram, faYelp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from "react-router-dom";
import "./index.scss"


const ops = [
  [16, 12],
  [16, 12],
  [16, 12],
  [16, 12],
  [16, 1],
  [12, 1],
  [12, 11]
]

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: window.innerWidth >= 768
    };
  }

  componentDidMount() {}

  handleClickLogo = () => {
    this.props.history.push('/');
  }

  dropdown() {
    const { showMenu } = this.state;
    const options = [
      {title: "Menu", path: "/menu"},
      {title: "Reserve", path: "https://squareup.com/appointments/book/irmxmolrq6ttrb/L2072RS7486MH/start"},
      {title: "About", path: "/about"},
      {title: "Contact", path: "/#contact"},
    ]
    return (
      <div className="dropdown" style={{display: showMenu ? "flex" : "none"}}>
        {options.map((option, index) => {
          return (
            <a className="dropdown-option" href={option.path} key={index}>
              {option.title}
            </a>
          );
        })}
      </div>
    )
  }

  render() {
    const footlessPaths = ['/order', '/order/cart']
    const {
      showMenu
    } = this.state;
    const day = ops[moment().day()]
    return (
      <Fragment>
        <div className="navbar">
          <div className="banner">
            {window.innerWidth < 768 ? 
            <button 
              className={showMenu ? "hamburger hamburger--elastic is-active" : "hamburger hamburger--elastic"} 
              type="button" 
              onClick={() => this.setState({showMenu: !showMenu})}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button> : null}
            <img className="lbng-logo" src={Logo} alt="logo" onClick={this.handleClickLogo}/>
            {window.innerWidth < 768 ? <div style={{minWidth: "70px"}}></div> : null}
          </div>
        </div>
        {this.dropdown()}
        <div className="body">
          {this.props.children}
          {footlessPaths.includes(window.location.pathname) ? null : <div className="footer">
            <div className="social-media">
              <a href="https://www.facebook.com/limitlessbng" target="_blank">
                <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
              </a>
              <a href="https://www.instagram.com/limitlessbng" target="_blank">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a href="https://www.yelp.ca/biz/limitless-bar-and-grill-thornhill" target="_blank">
                <FontAwesomeIcon icon={faYelp} size="2x" />
              </a>
              <a href="mailto:limitlessbarandgrill@gmail.com" target="_blank">
                <FontAwesomeIcon icon={faEnvelopeSquare} size="2x" />
              </a>
            </div>
            <small>&copy; {day} 2018, Limitless Bar & Grill</small>
          </div>}
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Layout);
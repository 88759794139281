import React, { Component } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

class ViewIndicator extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  indicator = () => {
    const {
      index,
      dots,
      active,
      inactive
    } = this.props;
    let row = []
    for(let i = 0; i < dots; i++) {
      row.push(
        <FontAwesomeIcon 
          key={i}
          icon={faCircle} 
          size="xs" 
          className="indicator-dot"
          style={index === i ? {color: active} : {color: inactive}}
          onClick={() => this.props.handleIndexChange(index)}
        />
      )
    }
    if(dots <= 1) {
      return null;
    }
    return row;
  }

  render() {
    return (
      <div className="view-indicator">
        {this.indicator()}
      </div>
    )
  }
}

export default ViewIndicator;
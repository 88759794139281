import React from 'react';
import './index.scss'

const Infinity = () => {
  return (
    <div className="infinity-spinner">
      <span class="ouro ouro3">
        <span class="left"><span class="anim"></span></span>
        <span class="right"><span class="anim"></span></span>
      </span>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="59.072" height="26.388" viewBox="0 0 59.072 26.388">
        <defs>
          <style>
            .path {
              fill: none;
            stroke: #231f20;
            stroke-miterlimit: 10;
            stroke-width: 2.5px;
        }
              </style>
        </defs>
        <path class="path" d="M281.3,267.819a11.944,11.944,0,0,1,0-23.888c10.85,0,21.834,23.888,32.684,23.888a11.944,11.944,0,0,0,0-23.888C303.171,243.931,292.109,267.819,281.3,267.819Z" transform="translate(-268.104 -242.681)" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="59.072" height="26.388" viewBox="0 0 59.072 26.388">
        <defs>
          <style>
            .a {
              fill: none;
            stroke: #231f20;
            stroke-miterlimit: 10;
            stroke-width: 1.75px;
            opacity: 0.2;
        }
            </style>
        </defs>
        <path class="a" d="M281.3,267.819a11.944,11.944,0,0,1,0-23.888c10.85,0,21.834,23.888,32.684,23.888a11.944,11.944,0,0,0,0-23.888C303.171,243.931,292.109,267.819,281.3,267.819Z" transform="translate(-268.104 -242.681)" />
      </svg> */}
    </div>
  )
}

export default Infinity;
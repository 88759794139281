import React from 'react';
import mf from '../../images/mf.jpg'

const Seafood = () => {
  return (
    <div className="seafood-container">
      <div className="header-img">
        <img src={mf} />
      </div>
      <div className="heading">Seafood</div>
      <div className="main-contents">
        <p>Items with rice can be substituted with garlic rice for $2</p>
        {contents.map(dish => {
          return (
            <div className="main-item">
              <p>{dish.name} {dish.price}</p>
              <p className="description">{dish.description.map(desc => <span>{desc}</span>)}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const contents = [
  {
    name: 'Shrimp Skewers (3)',
    description: ['Grilled garlic butter shrimp skewers with head and skin on'],
    price: '5.99',
    img: ''
  },
  {
    name: 'Oven Baked Clams',
    description: ['Fresh clams seasoned with garlic butter then baked to perfection'],
    price: '9.99',
    img: ''
  },
  {
    name: 'Grilled Milkfish Plate',
    description: ['Fresh grilled milkfish tossed in our mixed vegetables and house vinegar topped with Japanese mayo'],
    price: '14.99',
    img: ''
  },
  {
    name: 'Land & Sea Platter',
    description: ['Milkfish, 6 BBQ Pork Skewers'],
    price: '22.99',
    img: ''
  }
]

export default Seafood;
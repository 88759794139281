import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { uploadMenu } from '../../api/ProductAPI'
import Cookies from 'js-cookie';

const UploadMenu = props => {
  const [menuState, setMenuState] = useState(false)
  const { register, handleSubmit, errors } = useForm(); // initialize the hook
  const token = Cookies.get('access_token')
  const onSubmit = (data) => {
    let formData = new FormData()
    formData.append('file', data.menu[0])
    const params = {
      headers: { "Authorization": `Bearer ${token}` },
      method: 'POST',
      body: formData
    }
  fetch(`${process.env.REACT_APP_API_URL}/products/upload_csv?branch_id=${process.env.REACT_APP_BRANCH_ID}`, params)
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result)
        setMenuState(true)
      },
      (error) => {
        
      }
    )
  };
  return (
    // <form enctype="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
    //   <input type='file' name='file' ref={register({ required: true })} /> {/* register an input */}
    //   {errors.file && 'File required.'}
    //   <input type='submit' />
    //   <div style={{marginBottom: '2em'}}></div>
    // </form>
    <form enctype="multipart/form-data" method="post" onSubmit={handleSubmit(onSubmit)}>
      <input type='file' name='menu' ref={register({ required: true })} /> {/* register an input */}
      {errors.file && 'File required.'}
      <input type='submit' />
      <div style={{marginBottom: '2em'}}></div>
    </form>
  )
};

export default UploadMenu;

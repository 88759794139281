import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import QRCode from 'qrcode.react'
import { submitOrder } from '../../api/OrderAPI'
import './index.scss'

const Cart = ({ cart, setCart, view, handleSubmitOrder, removeFromCart, submitted }) => {
  const [viewCart, setViewCart] = useState(view)
  const [cartTotal, setCartTotal] = useState(0)
  const calculate = () => {
    let totalInCents = Object.values(cart).reduce((sum, items) => {
      let variantTotal = items.reduce((productSum, item) => {
        return productSum + parseFloat(item.customizedPrice || item.price) * 100 * item.quantity
      }, 0)
      return sum + variantTotal
    }, 0)
    return totalInCents / 100
  }
  useEffect(() => {
    const total = Object.keys(cart).reduce((sum, key) => {
      return sum + parseFloat(cart[key].price) * 100 * cart[key].quantity
    }, 0)
    setCartTotal(calculate())
  }, [cart])
  if(viewCart || submitted) {
    return (
      <div className='viewing-cart'>
        <div className='cart-title'>
          Your order {submitted ? 'has been submitted.' : ''}
        </div>
        <div className='cart-item'>
          <div className='cart-item-info'>
            <div className='cart-item__quantity'>
              Qty
            </div>
            <div className='cart-item__details center-align'>
              Item
            </div>
            <div className='cart-item__price'>
              Price
            </div>
          </div>
        </div>
        {Object.values(cart).map((items, i) => {
          return items.map((item, index) => {
            return (
              <div className='cart-item' key={index}>
                <div className='cart-item-info'>
                  <div className='cart-item__quantity'>
                    {item.quantity}
                  </div>
                  <div className='cart-item__details'>
                    {item.name}
                  </div>
                  <div className='cart-item__price'>
                    {((item.customizedPrice * 100 * item.quantity) / 100).toFixed(2)}
                  </div>
                </div>
                <div className='cart-item-customization'>
                  <ul>
                    {Object.values(item.selectedCustomizations).map((customization, idx) => {
                      return (
                        <li key={idx}><span>{customization.name}</span></li>
                      )
                    })}
                  </ul>
                </div>
                {submitted ? null : 
                <div className='cart-item__actions' onClick={() => removeFromCart(item.id)}>
                  Remove 1
                </div>}
              </div>
            )
          })
        })}
        <div className='cart-item'>
          <div className='cart-item-info'>
            <div className='cart-item__quantity'>

            </div>
            <div className='cart-item__details right-align'>
              Subtotal
            </div>
            <div className='cart-item__price'>
              {cartTotal}
            </div>
          </div>
          <div className='cart-item-info'>
            <div className='cart-item__quantity'>

            </div>
            <div className='cart-item__details right-align'>
              Total
            </div>
            <div className='cart-item__price'>
              {((cartTotal * 100 * 1.13) / 100).toFixed(2)}
            </div>
          </div>
        </div>
        {submitted ? 
        <div className='flexbox stick-bottom'>
          <Link className='flexbox__item solidback' to='/' onClick={() => setCart({})}>
            Return home
          </Link>
        </div> : 
        <div className='flexbox stick-bottom'>
          <Link className='flexbox__item solidtext' to='/order' onClick={() => setViewCart(false)}>Back to Menu</Link>
          <div className='flexbox__item solidback' onClick={handleSubmitOrder}>Submit Order</div>
        </div>}
      </div>
    )
  } else {
    if(cartTotal === 0) return null
    return (
      <Link to='/order/cart' className='floating-cart' onClick={() => setViewCart(true)}>
        <div className='cart-total'>
          View cart • ${cartTotal.toFixed(2)}
        </div>
      </Link>
    )
  }
}

export default Cart;
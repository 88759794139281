const getMenu = (callback) => {
  fetch(`${process.env.REACT_APP_API_URL}/products_by_category?branch_id=${process.env.REACT_APP_BRANCH_ID}`)
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result)
        callback(result)
      },
      (error) => {
        
      }
    )
}

const uploadMenu = (data, callback) => {
  console.log(data)
  const formData = new FormData()
  for(const name in data) {
    formData.append(name, data[name]);
  }
  const params = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data'
    },
    body: formData
  }
  fetch(`${process.env.REACT_APP_API_URL}/products/upload_csv?branch_id=${process.env.REACT_APP_BRANCH_ID}`, params)
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result)
        callback(true)
      },
      (error) => {
        
      }
    )
}

export {
  getMenu,
  uploadMenu
}
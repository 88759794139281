import React from 'react';

const ops = [
  {day: "Monday", open: "11:30AM - 2AM"},
  {day: "Tuesday", open: "11:30AM - 2AM"},
  {day: "Wednesday", open: "11:30AM - 2AM"},
  {day: "Thursday", open: "11:30AM - 2AM"},
  {day: "Friday", open: "11:30AM - 2AM"},
  {day: "Saturday", open: "11:30AM - 2AM"},
  {day: "Sunday", open: "11:30AM - 12AM"}
]

const Hours = () => {
  return (
    <div className="hours">
      {ops.map((h, index) => {
        return (
          <div className="open-time" key={index}>
            <span>{h.day}</span>
            <span>{h.open}</span>
          </div>
        )
      })}
    </div>
  )
}

export default Hours;
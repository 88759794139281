
import Cookies from 'js-cookie';

const login = (body, callback) => {
  console.log(body)
  const params = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }
  fetch(`${process.env.REACT_APP_API_URL}/login`, params)
    .then(res => res.json())
    .then(
      (result) => {
        Cookies.set('access_token', result.access_token)
        callback(result)
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        
      }
    )
}

const getCurrentUser = async() => {
  const token = Cookies.get('access_token')
  const params = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }
  let user;
  user = await fetch(`${process.env.REACT_APP_API_URL}/current_user`, params)
    .then(res => res.json())
    .then(
      (result) => {
        return result.logged_in_as
      },
      (error) => {
        
      }
    )
  return user
}

const logout = (callback) => {
  const params = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
  Cookies.remove('access_token')
  fetch(`${process.env.REACT_APP_API_URL}/logout`, params)
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result)
      },
      (error) => {
        
      }
    )
}

export {
  login,
  getCurrentUser,
  logout
}